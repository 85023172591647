<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">值班记录列表</h1>
    </div>
    <div slot="extra">
      <a-button class="m-r-12" icon="plus" type="primary" @click="handleAdd">新增
      </a-button>
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="所属部门">
              <a-tree-select
                style="width: 230px"
                v-model="searchParams.departmentId"
                :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                :treeData="orgTree"
                allowClear
                placeholder="请选择所属部门"
              ></a-tree-select>
            </a-form-item>
            <!--            <a-form-item label="班组">-->
            <!--            </a-form-item>-->
            <a-form-item label="值班员">
              <a-tree-select
                @change="onChangeUser"
                v-model="userStr"
                allowClear
                treeDefaultExpandAll
                treeNodeFilterProp="title"
                style="width: 230px"
                :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                :treeData="orgUserTreeData"
                placeholder="请选择值班员"
              ></a-tree-select>
            </a-form-item>
            <a-form-item label="日期">
              <a-range-picker style="width: 100%" :showTime="{ format: 'YYYY-MM-DD HH:mm:ss' }"
                              format="YYYY-MM-DD HH:mm:ss"
                              @change="handleModalPickerOk"></a-range-picker>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :customRow="handleView"
        :rowKey="(record) => record.id"
        :scroll="{x:1000}">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.page * searchParams.size + index + 1 }}
        </template>
        <template slot="noteDate" slot-scope="text, record">
          {{record.noteDate?moment(record.noteDate).format('YYYY-MM-DD HH:mm:ss'):'-'}}
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click.stop="handleEdit(record.id)" v-auth="auth_duty.DUTYNOTE_CREATE_AND_UPDATE">编辑</a>
          <a-divider type="vertical"/>
          <a @click.stop="handleDelete(record.id)" v-auth="auth_duty.DUTYNOTE_DELETE">删除</a>
        </template>
      </a-table>
    </a-card>
    <a-modal title="值班记事新增"
             :visible="addModal"
             @ok="handleAddOk"
             @cancel="closeAddModal"
             width="560px">
      <warning-form ref="addRef" type="add" @addSuccess="addSuccess" @addError="addError"></warning-form>
    </a-modal>
    <a-modal
      title="值班记事信息"
      :width="580"
      :visible="editModal"
      :maskClosable="false"
      @ok="handleEditOk"
      @cancel="closeEditModal">
      <warning-form ref="editRef" type="edit" @editSuccess="editSuccess" @editError="editError"></warning-form>
    </a-modal>

    <a-drawer
      title="值班记事详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      :width="800"
      :visible="detailDrawer"
      :mask="false">
      <Detail ref="viewRef"></Detail>
    </a-drawer>
  </page-layout>
</template>

<script>
  import PageLayout from '@/components/page/PageLayout'
  import entityCRUD from '../../common/mixins/entityCRUD'
  import SERVICE_URLS from '@/api/service.url'
  import { columns } from './common/common'
  import Detail from './Detail'
  import WarningForm from './Form'
  import moment from 'moment'

  export default {
    name: 'List',
    components: { PageLayout, Detail, WarningForm },
    mixins: [entityCRUD],
    data() {
      return {
        entityBaseUrl: SERVICE_URLS.dutyNote,
        searchParams: {
          page: 0,
          size: 10,
          direction: '',
          order: '',
          nodeContent: '',
          departmentId: undefined,
          dutyUserId: undefined,
          teamId: undefined,
          endTimeStr: '',
          startTimeStr: ''
        },
        initColumns: columns(),
        orgTree: [],
        detailDrawer: false,
        orgUserTreeData: [],
        userStr: undefined
      }
    },
    created() {
      this.getOrgTree()
    },
    methods: {
      moment,
      getOrgTree() {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: 'org_',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.orgUserTreeData = data.body
          }
        })
      },
      handleModalPickerOk(value) {
        if (value.length > 0) {
          this.searchParams.startTimeStr = value[0].format('YYYY-MM-DD HH:mm:ss')
          this.searchParams.endTimeStr = value[1].format('YYYY-MM-DD HH:mm:ss')
        } else {
          this.searchParams.startTimeStr = ''
          this.searchParams.endTimeStr = ''
        }
        this.search()
      },
      handleView(record) {
        return {
          on: {
            click: () => {
              this.detailDrawer = true
              this.$nextTick(() => {
                this.$refs.viewRef.loadData(record.id)
              })
            }
          }
        }
      },
      onChangeUser() {
        this.searchParams.dutyUserId = this.userStr ? this.userStr.split('_')[2] : undefined
      }
    }
  }
</script>
