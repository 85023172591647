<template>
  <a-spin :spinning="spinShow">
    <a-form>
      <a-row>
        <a-col :span="12">
          <a-form-item label="日期" :label-col="labelCol" :wrapper-col="wrapperCol">
            {{moment(formItem.noteDate).format('YYYY-MM-DD HH:mm:ss')||'-'}}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="所属部门" :label-col="labelCol" :wrapper-col="wrapperCol">
            {{formItem.departmentName||'-'}}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="值班员" :label-col="labelCol" :wrapper-col="wrapperCol">
            {{formItem.dutyUsername||'-'}}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="记录内容" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
            {{formItem.noteContent|| '-'}}
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="备注" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
            {{formItem.remark||'-'}}
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import moment from 'moment'
  import { formVO } from './common/common'

  export default {
    name: 'WarningDetail',
    components: {},
    mixins: [],
    data () {
      return {
        spinShow: false,
        ifSub: false,
        formItem: formVO(),
        labelCol: { span: 5 },
        wrapperCol: { span: 17 }
      }
    },
    methods: {
      moment,
      loadData (id) {
        this.$http(this, {
          url: SERVICE_URLS.dutyNote.view,
          params: { id },
          noTips: true,
          success: (data) => {
            Object.assign(this.formItem, data.body)
          }
        })
      }
    }
  }
</script>

<style>
  .ant-col-3{
    width: 10.5%;
  }
</style>
